/**
 * This file was automatically generated by Strapi.
 * Any modifications made will be discarded.
 */
import ckeditor5 from "@_sh/strapi-plugin-ckeditor/strapi-admin";
import documentation from "@strapi/plugin-documentation/strapi-admin";
import i18N from "@strapi/plugin-i18n/strapi-admin";
import seo from "@strapi/plugin-seo/strapi-admin";
import usersPermissions from "@strapi/plugin-users-permissions/strapi-admin";
import generateData from "strapi-plugin-generate-data/strapi-admin";
import menus from "strapi-plugin-menus/strapi-admin";
import { renderAdmin } from "@strapi/strapi/admin";

import customisations from "../../src/admin/app.tsx";

renderAdmin(document.getElementById("strapi"), {
  customisations,

  plugins: {
    ckeditor5: ckeditor5,
    documentation: documentation,
    i18n: i18N,
    seo: seo,
    "users-permissions": usersPermissions,
    "generate-data": generateData,
    menus: menus,
  },
});
