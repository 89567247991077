export default {
  config: {
    locales: [
      'en',
      'vi',
    ],
  },
  bootstrap(app: any) {
    console.log(app);
  },
};
